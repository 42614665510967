/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { getManifest } from "./functions/manifest";

const FacebookPixel = () => {
	const [data, setData] = useState({});
	const [pixel, setPixel] = useState();
	const [runScript, setRunScript] = useState(false);
	const [myLocation, setMyLocation] = useState({});

	useEffect(() => {
		const location = window.location;
		const res = getData();
		check(location, res);
	}, []);

	const getData = async () => {
		const manifest = await getManifest();
		const { data } = manifest;
		var xhr = new XMLHttpRequest();
		xhr.open(
			"GET",
			`${process.env.REACT_APP_API_HOST}/api/Apps/Google/getApp?id=${data.app_id}`,
			true,
		);
		xhr.onreadystatechange = function () {
			if (xhr.readyState === XMLHttpRequest.DONE) {
				if (xhr.status === 200) {
					const res = JSON.parse(xhr.responseText);
					setData(res.data);
					return res.data;
				} else {
					console.error("There was a problem with the request.");
				}
			}
		};
		xhr.send();
	};

	const getIp = async () => {
		try {
			const response = await fetch("https://test.traffhunt.com/api/getUserIP");

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}
			const resId = await response.json();

			return resId.data;
		} catch (error) {
			console.error("Failed to get IP:", error);
			return null;
		}
	};

	const check = async (location, info) => {
		const date = setDateCookie();
		const params = getUrlParams(location);

		setMyLocation(location);
		setPixel(params.pixel);

		const obgCookies = parseCookies(document.cookie);

		if (!!Object.keys(obgCookies).length && Object.keys(obgCookies)[0] !== "") {
			if (!!Object.keys(obgCookies).includes("lead_pixel_id")) {
				const res = await fetch(
					`${process.env.REACT_APP_API_HOST}/api/LeadPixels/App/getLeadPixel?id=${obgCookies.lead_pixel_id}`,
				);
			} else {
				setRunScript(true);
			}
		} else {
			const { ip, user_agent } = await getIp();

			if (!Object.keys(params).length) {
				document.cookie = `ip=${ip}; path=${location.pathname}; expires=${date}`;
				document.cookie = `user_agent=${user_agent}; path=${location.pathname}; expires=${date}`;
				document.cookie = `link_id=${info?.data?.lnk_id}; path=${location.pathname}; expires=${date}`;
				return;
			}

			const {
				pixel,
				fbclid,
				campaign_id,
				campaign_name,
				adset_id,
				adset_name,
				ad_id,
				ad_name,
				placement,
				lnk_id,
			} = params;

			if (
				!!pixel &&
				!!fbclid &&
				!!campaign_id &&
				!!campaign_name &&
				!!adset_id &&
				!!adset_name &&
				!!ad_id &&
				!!ad_name &&
				!!placement &&
				!!lnk_id &&
				!isNaN(Number(pixel))
			) {
				const keys = Object.keys(params);
				const values = Object.values(params);

				for (let i = 0; i < keys.length; i++) {
					if (keys[i] === "lnk_id") {
						document.cookie = `link_id=${values[i]}; path=${location.pathname}; expires=${date}`;
					} else {
						document.cookie = `${keys[i]}=${values[i]}; path=${location.pathname}; expires=${date}`;
					}
				}

				document.cookie = `ip=${ip}; path=${location.pathname}; expires=${date}`;
				document.cookie = `user_agent=${user_agent}; path=${location.pathname}; expires=${date}`;

				setRunScript(true);
			} else {
				document.cookie = `ip=${ip}; path=${location.pathname}; expires=${date}`;
				document.cookie = `user_agent=${user_agent}; path=${location.pathname}; expires=${date}`;
				document.cookie = `link_id=${info?.data?.lnk_id}; path=${location.pathname}; expires=${date}`;
				return;
			}
		}
	};

	const parseCookies = cookieString => {
		const cookiePairs = cookieString.split(";");
		const cookiesObject = {};

		cookiePairs.forEach(pair => {
			const [key, value] = pair.trim().split("=");

			cookiesObject[key] = value;
		});

		return cookiesObject;
	};

	const getUrlParams = location => {
		const searchParams = new URLSearchParams(location.search);
		const paramsObject = {};
		for (const [key, value] of searchParams.entries()) {
			paramsObject[key] = value;
		}

		return paramsObject;
	};

	const setDateCookie = () => {
		const currentDate = new Date();
		const expiresDate = new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000);
		const expiresDateString = expiresDate.toUTCString();

		return expiresDateString;
	};

	return (
		<>
			{runScript && (
				<>
					<script>
						{(() => {
							!(function (f, b, e, v, n, t, s) {
								if (f.fbq) return;
								n = f.fbq = function () {
									n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
								};
								if (!f._fbq) f._fbq = n;
								n.push = n;
								n.loaded = !0;
								n.version = "2.0";
								n.queue = [];
								t = b.createElement(e);
								t.async = !0;
								t.src = v;
								s = b.getElementsByTagName(e)[0];
								s.parentNode.insertBefore(t, s);
							})(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
							fbq("init", pixel);
							fbq("track", "PageView");

							const get = async (fbc, fbp) => {
								const obgQuery = parseCookies(document.cookie);

								document.cookie = `fbc=${obgQuery._fbc}; path=${
									myLocation.pathname
								}; expires=${setDateCookie()}`;

								document.cookie = `fbp=${obgQuery._fbp}; path=${
									myLocation.pathname
								}; expires=${setDateCookie()}`;

								obgQuery.fbp = obgQuery._fbp;
								obgQuery.fbc = obgQuery._fbc;
								obgQuery.pixel_fb_id = obgQuery.pixel;
								delete obgQuery._fbp;
								delete obgQuery._fbc;
								delete obgQuery.pixel;

								const res = await fetch(
									`${process.env.REACT_APP_API_HOST}/api/LeadPixels/App/addLeadPixel`,
									{
										method: "POST",
										headers: {
											"Content-Type": "application/json", // Встановлюємо заголовок Content-Type для JSON
										},
										body: JSON.stringify(obgQuery),
									},
								);

								const result = await res.json();
								document.cookie = `lead_pixel_id=${result.data.id}; path=${
									myLocation.pathname
								}; expires=${setDateCookie()}`;
							};

							const getFbpc = () => {
								const fbc = document.cookie.match("(^|;)\\s*_fbc\\s*=\\s*([^;]+)");
								const fbp = document.cookie.match("(^|;)\\s*_fbp\\s*=\\s*([^;]+)");
								if (!fbc && !fbp) {
									const timeout = setTimeout(() => {
										getFbpc();
									}, 1000);
								} else {
									return get(fbc, fbp);
								}
							};

							getFbpc();
						})()}
					</script>
					<noscript>
						<img
							height="1"
							width="1"
							style="display:none"
							src={`https://www.facebook.com/tr?id=${pixel}&ev=PageView&noscript=1`}
						/>
					</noscript>
				</>
			)}
		</>
	);
};

export default FacebookPixel;
