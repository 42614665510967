/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from "react";
import { getDeviceAndBrowser } from "./functions/device";
import { getManifest } from "./functions/manifest";
import FacebookPixel from "./FacebookPixel";

const App = () => {
	const [status, setStatus] = useState("download");
	const [progress, setProgress] = useState(0);
	const [progressWidth, setProgressWidth] = useState(0);
	const [device, setDevice] = useState({});

	const [manifestData, setManifestData] = useState({});

	useEffect(() => {
		(async () => {
			const manifest = await getManifest();
			setManifestData(manifest);
		})();
	}, []);

	const parseCookies = cookieString => {
		const cookiePairs = cookieString.split(";");
		const cookiesObject = {};

		cookiePairs.forEach(pair => {
			const [key, value] = pair.trim().split("=");

			cookiesObject[key] = value;
		});

		return cookiesObject;
	};

	window.isPWA = function () {
		return (
			window.matchMedia("(display-mode: standalone)").matches ||
			window.navigator.standalone ||
			document.referrer.includes("android-app://")
		);
	};

	useEffect(() => {
		if (status !== "downloading") return;
		let progressWidth = 0;
		const interval = setInterval(() => {
			progressWidth += Math.min(100 - progressWidth, Math.floor(Math.random() * 5 + 1));
			setProgressWidth(progressWidth);

			if (progressWidth >= 100) {
				setStatus("install");
				clearInterval(interval);
			}
		}, Math.floor(Math.random() * 201 + 250));
	}, [progress, status]);

	useEffect(() => {
		setDevice(getDeviceAndBrowser()); //визначаємо девайс

    window.addEventListener("beforeinstallprompt", async function (e) {
			e.preventDefault();
			setStatus("install");
			window.deferredPrompt = e;
			console.log(e);
		});

		let intervalId = setInterval(async () => {
			let appInstalled = await navigator.getInstalledRelatedApps();

			if (appInstalled.length > 0) {
				setStatus("open");
				clearInterval(intervalId);
			}
		}, 500);
	}, []);

	const handleDownload = () => {
		if (device.deviceType === "Desktop") {
			return setStatus("install");
		}

		if (device.deviceType === "Android") {
			setStatus("downloading");
		}
	};

	const handleInstall = async () => {
		setStatus("installing");

		if (window.deferredPrompt != null) {
			window.deferredPrompt.prompt();

			const { outcome } = await window.deferredPrompt.userChoice;
			if (outcome === "accepted") {
				const { data } = manifestData;
				const obgCookies = parseCookies(document.cookie);

				const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/Leads/App/addLead`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						app_id: data.app_id,
						ip: obgCookies?.ip,
						user_agent: obgCookies?.user_agent,
						link_id: Number(obgCookies?.link_id),
						lead_pixel_id: obgCookies?.lead_pixel_id,
					}),
				});

				const response = await res.json();
				localStorage.setItem("lead_id", response.data.id);
			}
		}
	};

	const handleOpen = () => {
		if (window.isPWA()) {
			window.open("/pwa", "_self");
		} else {
			console.log(manifestData);
			window.open(`${manifestData.data.domain}/pwa`, "_blank");
		}
	};

	return (
		<>
			{/* <FacebookPixel /> */}
			<div className="layout">
				<div className="container">
					<div className="header">
						<div className="header-top">
							{status !== "verifying" && status !== "downloading" && status !== "installing" && (
								<img
									className="head-img"
									id="app_logo_large"
									src="images/icon.png"
								/>
							)}
							{(status === "verifying" || status === "installing") && (
								<div
									className="img-loading"
									id="loading_app_logo"
								>
									<img
										className="head-img"
										src="images/icon.png"
										id="app_logo_loading"
									/>
								</div>
							)}
							{status === "downloading" && (
								<div
									className="img-download"
									id="download_app_logo"
								>
									<img
										className="head-img"
										src="images/icon.png"
										id="app_logo_svg"
									/>
									<svg
										width="72"
										height="72"
										className="img-loading-circle-svg"
										id="progressDownload"
									>
										<circle
											id="progress"
											cx="36"
											cy="36"
											r="35"
											stroke="#0B57CF"
											strokeWidth="2"
											strokeDashoffset={`${220 - (progressWidth * 220) / 100}`}
											fill="none"
										/>
									</svg>
								</div>
							)}
							<div>
								<h1 id="appName">App</h1>
								{(status === "download" ||
									status === "open" ||
									status === "installing" ||
									status === "install") && (
									<div
										id="noinstall_subtitle"
										className="header-links"
									>
										<span>Google</span>
										<br />
										<p id="headerContains">Contains ads &middot; In-app purchases</p>
									</div>
								)}
								{status === "downloading" && (
									<div
										id="installing_subtitle"
										className="header-loding"
									>
										{/* <h3>Installing...</h3>  */}
										<div>
											<div>
												<div id="progressWidth"></div>
											</div>
											<div id="progressWidth-info">{progressWidth}% from 12.1 MB</div>
										</div>

										<p>
											<img src="/images/other/play.jpeg" />
											<span>Checked by Google Play Protect</span>
										</p>
									</div>
								)}
								{status === "verifying" && (
									<div
										id="veriying_subtitle"
										className="header-loding"
									>
										<h3 id="titleVerifying">Verifying...</h3>
										<p>
											<img src="/images/other/play.jpeg" />
											<span>Verifying by Google Play Protect...</span>
										</p>
									</div>
								)}
							</div>
						</div>
						<div
							id="statistics_view"
							className="mob-stats"
						>
							<div className="inner">
								<div className="mob-stat">
									<span className="app-comp__mobile-info-rating">
										4.1
										<svg
											enableBackground="new 0 0 24 24"
											version="1.1"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
										>
											<g fill="none">
												<path d="M0,0h24v24H0V0z"></path>
												<path d="M0,0h24v24H0V0z"></path>
											</g>
											<path d="M12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27z"></path>
										</svg>
									</span>
									<p>
										<span id="headerReviews">34K reviews</span>
										<img
											src="images/other/info.svg"
											alt="#"
											style={{ width: "12px", marginLeft: "3px" }}
										/>
									</p>
								</div>
								<div className="mob-stat">
									<span className="app-comp__mobile-info-icon">
										<svg
											viewBox="0 0 18 18"
											xmlns="http://www.w3.org/2000/svg"
											width="20"
										>
											<g transform="translate(21.552 22.5) rotate(180)">
												<path
													transform="translate(7.552 7.652)"
													d="M.625,0h8.75A.68.68,0,0,1,10,.723a.68.68,0,0,1-.625.723H.625A.68.68,0,0,1,0,.723.68.68,0,0,1,.625,0Z"
													data-name="Path 288"
												></path>
												<g transform="translate(17.552 20.797) rotate(180)">
													<path
														d="M0,0H9.666V9.666H0Z"
														fill="none"
														data-name="Path 289"
													></path>
													<path
														transform="translate(-4.408 -3.203)"
														d="M8.752,4.642V11.81L5.536,8.678a.677.677,0,0,0-.936,0,.627.627,0,0,0,0,.9l4.343,4.229a.669.669,0,0,0,.929,0l4.343-4.229a.627.627,0,0,0,0-.9.669.669,0,0,0-.929,0L10.07,11.81V4.642a.659.659,0,0,0-1.318,0Z"
														data-name="Path 290"
													></path>
												</g>
												<rect
													transform="translate(4.552 5.5)"
													width="16"
													height="16"
													rx="2"
													fill="none"
													stroke="#000"
													strokeWidth="2"
													data-name="Rectangle 123"
												></rect>
											</g>
										</svg>
									</span>
									<p>12.1 MB</p>
								</div>
								<div className="mob-stat">
									<img
										src="images/other/age18.webp"
										alt=""
										style={{ width: "23px", marginBottom: "3px" }}
									/>
									<p>
										<span id="headerRated">Rated for 18+</span>
										<img
											src="images/other/info.svg"
											alt="#"
											style={{ width: "12px", marginLeft: "3px" }}
										/>
									</p>
								</div>
								<div className="mob-stat">
									<span className="app-comp__mobile-info-rating">1M+</span>
									<p id="headerDownloads">Downloads</p>
								</div>
							</div>
						</div>
						{/* {status === "open" && (
							<div
								className="btns"
								id="installing_btns"
								// style={{ display: "none" }}
							>
								<button
									style={{
										fontFamily: "Google Sans, Roboto, Arial, sans-serif",
										fontWeight: "500",
									}}
									id="cancel_installing_btn"
									type="button"
									className="btn-secondary cancel"
								>
									Cancel
								</button>
								<button
									style={{
										fontFamily: "Google Sans, Roboto, Arial, sans-serif",
										fontWeight: "500",
									}}
									type="button"
									id="open_installing_btn"
									className="main-btn disabled"
								>
									Open
								</button>
							</div>
						)} */}
						{status === "install" && (
							<button
								style={{
									fontFamily: "Google Sans, Roboto, Arial, sans-serif",
									fontWeight: "500",
								}}
								type="button"
								id="install_button"
								className="main-btn"
								onClick={handleInstall}
							>
								Install
							</button>
						)}
						{status === "installing" && (
							<button
								style={{
									fontFamily: "Google Sans, Roboto, Arial, sans-serif",
									fontWeight: "500",
								}}
								type="button"
								id="installing_button"
								className="main-btn"
							>
								Installing...
							</button>
						)}
						{(status === "download" || status === "downloading") && (
							<button
								style={{
									fontFamily: "Google Sans, Roboto, Arial, sans-serif",
									fontWeight: "500",
								}}
								type="button"
								id="download_button"
								className="main-btn"
								onClick={handleDownload}
							>
								Download
							</button>
						)}
						{status === "verifying" && (
							<button
								style={{
									fontFamily: "Google Sans, Roboto, Arial, sans-serif",
									fontWeight: "500",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
								type="button"
								id="verify_button"
								className="main-btn disabled"
							>
								Verifying...
							</button>
						)}
						{status === "open" && (
							<div
								id="installed_btns"
								className="btns"
							>
								<button
									style={{
										fontFamily: "Google Sans, Roboto, Arial, sans-serif",
										fontWeight: "500",
									}}
									type="button"
									className="btn-secondary"
									id="delete_button"
								>
									Delete
								</button>
								<button
									style={{
										fontFamily: "Google Sans, Roboto, Arial, sans-serif",
										fontWeight: "500",
									}}
									id="open_app_button"
									type="button"
									className="main-btn"
									onClick={handleOpen}
								>
									Open
								</button>
							</div>
						)}
					</div>
					<div className="line-sections"></div>
					<div className="current-platform">
						<div className="current-platform_platform">
							<img
								src="images/other/done.svg"
								alt="#"
							/>
							<span id="btnDevice">Phone</span>
						</div>
					</div>
					<div className="info">
						<div className="Gallery_gallery__W86p0">
							<div
								className="Gallery_galleryContainer__-p-ic"
								id="gallery"
							></div>
						</div>

						<div className="discrip">
							<div className="discrip-head">
								<h3 id="aboutAppTitle">About this app</h3>
								<img
									src="images/other/arrow_forward.svg"
									alt="#"
									style={{ width: "24px", height: "24px" }}
								/>
							</div>
							<div className="text">
								<p id="aboutAppText">
									The official application for the Aviator game from 1win allows you to earn real
									money and withdraw it instantly to your preferred account.
									<br />
									<br />
									Hundreds of thousands of Indians have already changed their fortunes with the help
									of this application, and you can too. Simply guess how the "aviator" plane will
									fly and multiply your money.
									<br />
									<br />
									Supported payment systems include PhonePe, Gpay, Paytm, UPI, and many more!
									Download now and join a cool community with other players, forgetting about the
									problem of running out of money.
									<br />
									<br />
								</p>
							</div>
						</div>
						<div className="discrip">
							<div className="discrip-head">
								<h3 id="dataSfetyTitle">Data safety</h3>
								<img
									src="images/other/arrow_forward.svg"
									alt="#"
									style={{ width: "24px", height: "24px" }}
								/>
							</div>
							<div className="text">
								<p id="dataSfetyText">
									To control safety, you need to know how developers collect and share your data.
									Security and privacy methods may vary depending on how you use the app, your
									region, and your age. The information below is provided by developers and may
									change in the future.
								</p>
							</div>
							<div className="frame">
								<div className="frame-row">
									<img src="images/other/icon-share.png" />
									<div className="frame-text">
										<h4 id="frameTitle1">Data is not shared with third parties</h4>
										<p id="frameText1">
											Learn more about <span>how developers declare sharing data</span>
										</p>
									</div>
								</div>
								<div className="frame-row">
									<img src="images/other/icon-cloud.png" />
									<div className="frame-text">
										<h4 id="frameTitle2">Data is not collected</h4>
										<p id="frameText2">
											Learn more about <span>how developers declare collecting data</span>
										</p>
									</div>
								</div>
								<a
									href="#"
									className="btn-link"
									id="seeDetails"
								>
									See details
								</a>
							</div>
						</div>
						<div className="discrip rating">
							<div className="discrip-head">
								<h3 id="ratingTitle">Rating and reviews</h3>
								<img
									src="images/other/arrow_forward.svg"
									alt="#"
									style={{ width: "24px", height: "24px" }}
								/>
							</div>
							<div className="text">
								<p id="ratingText">
									Ratings and reviews have been verified. They were made by users with the same type
									of device as yours.
								</p>
							</div>
							<div className="inner">
								<div className="left">
									<div className="total-rating">4.7</div>
									<div className="stars">
										<img src="images/other/star-blue-full.png" />
										<img src="images/other/star-blue-full.png" />
										<img src="images/other/star-blue-full.png" />
										<img src="images/other/star-blue-full.png" />
										<img src="images/other/star-blue-half.png" />
									</div>
									<div className="user">32425</div>
								</div>
								<div className="right">
									<div className="chart">
										<div className="chart-bar bar1">
											<p>5</p>
											<span></span>
										</div>
										<div className="chart-bar bar2">
											<p>4</p>
											<span></span>
										</div>
										<div className="chart-bar bar3">
											<p>3</p>
											<span></span>
										</div>
										<div className="chart-bar bar4">
											<p>2</p>
											<span></span>
										</div>
										<div className="chart-bar bar5">
											<p>1</p>
											<span></span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="comments">
							<div className="comment">
								<div className="comment-info">
									<div className="top">
										<div className="user-info">
											<div className="avatar">
												<img
													className="lazy_load"
													src="images/users/1.jpg"
													data-src="images/users/1.jpg"
												/>
											</div>
											<h4>Raj Reddy</h4>
											<img
												className="dots"
												src="images/other/dots.png"
											/>
										</div>
										<div className="user-rating">
											<div className="stars">
												<img src="images/other/star-blue-full.png" />
												<img src="images/other/star-blue-full.png" />
												<img src="images/other/star-blue-full.png" />
												<img src="images/other/star-blue-full.png" />
												<img src="images/other/star-blue-full.png" />
											</div>
											<p>24/03/2024</p>
										</div>
									</div>
									<div className="comment-text">
										The Aviator app is simply amazing! Started using it last month and already won
										35,000 rupees. Highly recommend!
									</div>
									<div className="helpful">96 people found this review helpful</div>
									<div className="helpful-que-container">
										<span>Was this review helpful?</span>
										<div className="helpful-que-container_buttons">
											<div>Yes</div>
											<div>No</div>
										</div>
									</div>
									<div className="responce">
										<div className="responce-header">
											<span>1win. Ltd.</span>
											<span>24/03/2024</span>
										</div>
										<div className="responce-text">
											Thank you for sharing your experience! We're thrilled to hear that you found
											success with the Aviator app. Keep up the great work!
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="all-reviews">
							<a
								href="#"
								className="btn-link"
								id="allReviews"
							>
								See all reviews
							</a>
						</div>
					</div>
					<div className="support">
						<input
							type="checkbox"
							name=""
							id="openSupport"
						/>
						<label
							className="blockHeader"
							htmlFor="openSupport"
						>
							<h3 id="appSupportTitle">App support</h3>
							<div id="labelSupport">
								<img
									src="images/other/arrow_down.svg"
									alt="#"
									style={{ width: "24px", height: "24px" }}
								/>
								<img
									src="images/other/arrow_up.svg"
									alt="#"
									style={{ width: "24px", height: "24px" }}
								/>
							</div>
						</label>
						<div className="support__list">
							<div className="support__item">
								<span
									className=""
									style={{
										backgroundImage: "url(images/other/public.svg)",
										width: "18px",
										backgroundPosition: "top",
										backgroundSize: "contain",
										backgroundRepeat: "no-repeat",
									}}
								></span>
								<div className="support__item__titleWrapper">
									<div
										className="support__item__title"
										id="appSupportItem1"
									>
										Website
									</div>
								</div>
							</div>
							<div className="support__item">
								<span
									className=""
									style={{
										backgroundImage: "url(images/other/mail.svg)",
										width: "18px",
										backgroundPosition: "top",
										backgroundSize: "contain",
										backgroundRepeat: "no-repeat",
									}}
								></span>
								<div className="support__item__titleWrapper">
									<div
										className="support__item__title"
										id="appSupportItem2"
									>
										Support email
									</div>
									<div className="support__item__subTitle">support@anino.co</div>
								</div>
							</div>
							<div className="support__item">
								<span
									className=""
									style={{
										backgroundImage: "url(images/other/location.svg)",
										width: "18px",
										backgroundPosition: "top",
										backgroundSize: "contain",
										backgroundRepeat: "no-repeat",
									}}
								></span>
								<div className="support__item__titleWrapper">
									<div
										className="support__item__title"
										id="appSupportItem3"
									>
										Address
									</div>
									<div className="support__item__subTitle">
										30 Cecil Street, #19-08 Prudential Tower, Singapore, 049712
									</div>
								</div>
							</div>
							<div className="support__item">
								<span
									className=""
									style={{
										backgroundImage: "url(images/other/shield.svg)",
										width: "18px",
										backgroundPosition: "top",
										backgroundSize: "contain",
										backgroundRepeat: "no-repeat",
									}}
								></span>
								<div className="support__item__titleWrapper">
									<div
										className="support__item__title"
										id="appSupportItem4"
									>
										Privacy Policy
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="similarGames">
						<div className="discrip-head">
							<h3 id="similarGamesTitle">Similar games</h3>
							<img
								src="images/other/arrow_forward.svg"
								alt="#"
								style={{ width: "24px", height: "24px" }}
							/>
						</div>
						<div className="similarGames-container">
							<div className="games__list">
								<div className="games__itemWrapper">
									<div className="games__item">
										<img
											src="images/games/unnamed.webp"
											alt=""
										/>
										<div className="games__item__info">
											<div className="games__item__textContainer">
												<span>Jackpot Master™ Slots - Casino</span>
											</div>
											<div className="games__item__raiting">
												<span>4.9</span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													enableBackground="new 0 0 24 24"
													height="24px"
													viewBox="0 0 24 24"
													width="12px"
													fill="rgb(95,99,104)"
												>
													<g>
														<path
															d="M0 0h24v24H0V0z"
															fill="none"
														></path>
														<path
															d="M0 0h24v24H0V0z"
															fill="none"
														></path>
													</g>
													<g>
														<path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
													</g>
												</svg>
											</div>
										</div>
									</div>
								</div>
								<div className="games__itemWrapper">
									<div className="games__item">
										<img
											src="images/games/unnamed (1).webp"
											alt=""
										/>
										<div className="games__item__info">
											<div className="games__item__textContainer">
												<span>Slotsmash™ - Casino Slots Game</span>
											</div>

											<div className="games__item__raiting">
												<span>4.4</span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													enableBackground="new 0 0 24 24"
													height="24px"
													viewBox="0 0 24 24"
													width="12px"
													fill="rgb(95,99,104)"
												>
													<g>
														<path
															d="M0 0h24v24H0V0z"
															fill="none"
														></path>
														<path
															d="M0 0h24v24H0V0z"
															fill="none"
														></path>
													</g>
													<g>
														<path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
													</g>
												</svg>
											</div>
										</div>
									</div>
								</div>
								<div className="games__itemWrapper">
									<div className="games__item">
										<img
											src="images/games/unnamed (2).webp"
											alt=""
										/>
										<div className="games__item__info">
											<div className="games__item__textContainer">
												<span>Jackpot Wins - Slots Casino</span>
											</div>

											<div className="games__item__raiting">
												<span>4.7</span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													enableBackground="new 0 0 24 24"
													height="24px"
													viewBox="0 0 24 24"
													width="12px"
													fill="rgb(95,99,104)"
												>
													<g>
														<path
															d="M0 0h24v24H0V0z"
															fill="none"
														></path>
														<path
															d="M0 0h24v24H0V0z"
															fill="none"
														></path>
													</g>
													<g>
														<path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
													</g>
												</svg>
											</div>
										</div>
									</div>
								</div>
								<div className="games__itemWrapper">
									<div className="games__item">
										<img
											src="images/games/unnamed (3).webp"
											alt=""
										/>
										<div className="games__item__info">
											<div className="games__item__textContainer">
												<span>Quick Cash Classic Slots</span>
											</div>

											<div className="games__item__raiting">
												<span>4.7</span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													enableBackground="new 0 0 24 24"
													height="24px"
													viewBox="0 0 24 24"
													width="12px"
													fill="rgb(95,99,104)"
												>
													<g>
														<path
															d="M0 0h24v24H0V0z"
															fill="none"
														></path>
														<path
															d="M0 0h24v24H0V0z"
															fill="none"
														></path>
													</g>
													<g>
														<path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
													</g>
												</svg>
											</div>
										</div>
									</div>
								</div>
								<div className="games__itemWrapper">
									<div className="games__item">
										<img
											src="images/games/unnamed (4).webp"
											alt=""
										/>
										<div className="games__item__info">
											<div className="games__item__textContainer">
												<span>DoubleU Casino™ - Vegas Slots</span>
											</div>

											<div className="games__item__raiting">
												<span>4.4</span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													enableBackground="new 0 0 24 24"
													height="24px"
													viewBox="0 0 24 24"
													width="12px"
													fill="rgb(95,99,104)"
												>
													<g>
														<path
															d="M0 0h24v24H0V0z"
															fill="none"
														></path>
														<path
															d="M0 0h24v24H0V0z"
															fill="none"
														></path>
													</g>
													<g>
														<path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
													</g>
												</svg>
											</div>
										</div>
									</div>
								</div>
								<div className="games__itemWrapper">
									<div className="games__item">
										<img
											src="images/games/unnamed (5).webp"
											alt=""
										/>
										<div className="games__item__info">
											<div className="games__item__textContainer">
												<span>Billionaire Casino Slots 777</span>
											</div>
											<div className="games__item__raiting">
												<span>4.4</span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													enableBackground="new 0 0 24 24"
													height="24px"
													viewBox="0 0 24 24"
													width="12px"
													fill="rgb(95,99,104)"
												>
													<g>
														<path
															d="M0 0h24v24H0V0z"
															fill="none"
														></path>
														<path
															d="M0 0h24v24H0V0z"
															fill="none"
														></path>
													</g>
													<g>
														<path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
													</g>
												</svg>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="Layout_imagePopup__CCOgX">
				<header className="Layout_imagePopup__header__n7kd7">
					<button>
						<img
							src="images/other/arrow_back.svg"
							alt="#"
							style={{ width: "24px", height: "24px" }}
						/>
					</button>
				</header>
				<section className="Layout_imagePopup___nwj57">
					<button className="Gallery_buttonPrev__GjkHI Layout_buttonPrev__IU3Mn">
						<img
							src="images/other/arrow_left.svg"
							alt="#"
							style={{ width: "30px", height: "36px" }}
						/>
					</button>
					<img
						src="#"
						className="Layout_imagePopup__image"
					/>
					<button className="Gallery_buttonNext__+NwwV Layout_buttonNext__p4gU9">
						<img
							src="images/other/arrow_right.svg"
							alt="#"
							style={{ width: "30px", height: "36px" }}
						/>
					</button>
				</section>
			</div>
			{/* <div
				className="loaderContainer"
				style={{display: 'flex'}}
			>
				<span className="loader"></span>
			</div> */}
		</>
	);
};

export default App;
