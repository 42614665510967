const loadJsonFile = async url => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error loading the JSON file:", error);
  }
};

export const getManifest = async url => {
  // let manifest = await loadJsonFile("manifest.json");

  // if (manifest && manifest.data && manifest.firebase) {
  //   // console.log(manifest);
  //   return manifest;
  // }
  return {}
};