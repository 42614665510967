import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";
import Pwa from "./Pwa";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { setSettings } from "./functions/settingsFunc";

const router = createBrowserRouter([
	{
		path: "/pwa",
		element: <Pwa />,
	},
	{
		path: "/",
		element: <App />,
	},
]);

// const link = document.getElementById("manifest");
// link.setAttribute("href", '/app-manifest.json')

const data = {
	name: "1win Aviator",
	short_name: "1win Aviator",
	scope: "https://playgoogle.io",
	start_url: "https://playgoogle.io/pwa",
	display: "standalone",
	background_color: "#ffffff",
	theme_color: "#2196f3",
	icons: [
		{
			src: "https://playgoogle.io/images/icon.png",
			sizes: "64x64 32x32 24x24 16x16",
			type: "image/png",
		},
		{
			src: "https://playgoogle.io/images/icon.png",
			sizes: "120x120",
			type: "image/png",
		},
		{
			src: "https://playgoogle.io/images/icon.png",
			sizes: "192x192",
			type: "image/png",
			purpose: "maskable any",
		},
		{
			src: "https://playgoogle.io/images/icon.png",
			sizes: "512x512",
			type: "image/png",
			purpose: "maskable any",
		},
	],
	related_applications: [
		{
			platform: "webapp",
			url: "https://playgoogle.io/manifest.json",
		},
	],
};

const MANIFEST_KEY = "app-manifest";

// Функція для перевірки, чи існує маніфест у localStorage
const checkManifestExists = () => {
  return localStorage.getItem(MANIFEST_KEY) !== null;
};

// Функція для збереження маніфесту в localStorage
const saveManifestToLocalStorage = () => {
  localStorage.setItem(MANIFEST_KEY, JSON.stringify(data));
  console.log("Manifest збережено у localStorage");
};

// Функція для отримання маніфесту з localStorage
const loadManifestFromLocalStorage = () => {
  const manifestContent = localStorage.getItem(MANIFEST_KEY);
  return manifestContent ? manifestContent : null;
};

// Функція для підключення маніфесту до HTML
const attachManifestToHTML = (manifestContent) => {
  const blob = new Blob([manifestContent], { type: "application/json" });
  const manifestURL = URL.createObjectURL(blob);

  // Створюємо або знаходимо елемент link
  let manifestElement = document.querySelector('link[rel="manifest"]');
  if (!manifestElement) {
    manifestElement = document.createElement("link");
    manifestElement.rel = "manifest";
    document.head.appendChild(manifestElement);
  }

  // Прив'язуємо URL блоба до manifest
  manifestElement.href = manifestURL;

  console.log("Manifest підключено до HTML:", manifestURL);
};

// Основна функція для перевірки і створення маніфесту
const createManifestIfNotExists = () => {
  if (!checkManifestExists()) {
    console.log("Маніфест не існує, створюємо новий...");
    saveManifestToLocalStorage();
  } else {
    console.log("Маніфест вже існує, не створюємо.");
  }

  // Завжди підключаємо маніфест після перевірки
  const manifestContent = loadManifestFromLocalStorage();
  if (manifestContent) {
    attachManifestToHTML(manifestContent);
  }
};

// Викликаємо функцію перевірки і створення маніфесту
createManifestIfNotExists();

ReactDOM.createRoot(document.getElementById("root")).render(<RouterProvider router={router} />);
setSettings();
