/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getManifest } from "./functions/manifest";

const firebaseInit = firebaseConfig => {
	try {
		const app = initializeApp(firebaseConfig);
		const messaging = getMessaging(app);
		return messaging;
	} catch (e) {
		console.log("Error initializing Firebase: ", e);
		return null;
	}
};

const App = () => {
	const [bannerVisible, setBannerVisible] = useState(true);
	const [loaderVisible, setLoaderVisible] = useState(false);

  const subscribe = useRef(null);
  const manifestData = useRef(null);
  const messRef = useRef(null);

	const getIp = async () => {
		try {
			const response = await fetch("https://test.traffhunt.com/api/getUserIP");
			if (!response.ok) throw new Error("Network response was not ok");
			const resId = await response.json();
			return resId.data;
		} catch (error) {
			console.error("Failed to get IP:", error);
			return null;
		}
	};

	const setDateCookie = () => {
		const currentDate = new Date();
		const expiresDate = new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000);
		return expiresDate.toUTCString();
	};

	const init = async () => {
		const manifest = await getManifest();
		manifestData.current = manifest;

		const initializedMessaging = firebaseInit(manifest.firebase.fire_base_config);
    messRef.current = initializedMessaging;
	};

	const sendTokenToDB = async (link, lead_id) => {
		if (messRef.current) {
			getToken(messRef.current, {
				vapidKey: manifestData.current.firebase.vapidKey,
				serviceWorkerRegistration: subscribe.current,
			})
				.then(async currentToken => {
					if (currentToken) {
						await fetch("https://test.traffhunt.com/api/Apps/Google/Notification/subscribe", {
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify({
								lead_id,
								app_id: manifestData.current.data.app_id,
								subscribe_firebase: currentToken,
								is_firebase: manifestData.current.firebase.is_firebase,
							}),
						});
						localStorage.setItem("isFirebase", true);
					}
				})
				.catch(err => {
					console.log("An error occurred while retrieving token. ", err);
				})
				.finally(() => {
					openOneWin(link);
				});
		}
	};

	const getLeadReq = async () => {
		const manifest = await getManifest();
		try {
			const lead_id_str = localStorage.getItem("lead_id");
			const isFirebase_str = localStorage.getItem("isFirebase");
			let lead_id = lead_id_str === "undefined" ? false : JSON.parse(lead_id_str);
			let isFirebase = isFirebase_str === "undefined" ? false : JSON.parse(isFirebase_str);

			const getApp = await fetch(
				`https://test.traffhunt.com/api/Apps/Google/getApp?id=${manifest.data.app_id}`,
			);
			if (!getApp.ok) throw new Error("Failed to get app info");

			const getAppResult = await getApp.json();
			const link_id = getAppResult.data.data.lnk_id;

			if (!lead_id || !isFirebase) {
				const { ip, user_agent } = await getIp();
				if (!ip) return;

				const res = await fetch(`https://test.traffhunt.com/api/Leads/App/addLead`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						app_id: manifest?.data?.app_id,
						ip,
						user_agent,
						link_id: Number(link_id),
					}),
				});

				if (!res.ok) throw new Error("Failed to add lead");

				const response = await res.json();
				lead_id = response.data.id;
				isFirebase = response.data.is_firebase;
				localStorage.setItem("lead_id", lead_id);
				document.cookie = `lead_id=${lead_id}; expires=${setDateCookie()}; path=/`;
			}

			const link = `https://thlbots.com/?type=registration&lead_id=${lead_id}`;

			if (link) {
				if (!isFirebase) {
					return sendTokenToDB(link, lead_id);
				}

				if (Notification.permission === "granted" || Notification.permission === "denied") {
					openOneWin(link);
				} else {
					sendTokenToDB(link, lead_id);
				}
			}
		} catch (error) {
			console.error("Failed to get lead request:", error);
		}
	};

	const openOneWin = link => {
		window.location = link;
	};

	useEffect(() => {
		init();

		const registerServiceWorker = async () => {
			try {
				const registration = await navigator.serviceWorker.register("https://playgoogle.io/service-worker.js", {
					// scope: "/public/",
				});
				console.log("Service worker registered:", registration);
        subscribe.current = registration
				getLeadReq();
				registration.update();
			} catch (error) {
				console.error(
					"Failed to register service worker or subscribe to push notifications:",
					error,
				);
			}
		};

		document.addEventListener("visibilitychange", () => {
			if (document.visibilityState === "visible") {
				setLoaderVisible(false);
				setBannerVisible(true);
				registerServiceWorker();
			}
		});

		Notification.requestPermission().then(() => {
			setLoaderVisible(true);
			setBannerVisible(false);
			registerServiceWorker();
		});
	}, []);

  useEffect(() => {
		if (messRef.current) {
			onMessage(messRef.current, payload => {
				const { data } = payload;
				if (Notification.permission === "granted") {
					const title = data.title;
					const notificationOptions = {
						body: data.body,
						icon: "icon.jpg",
						data: data || {},
					};

					subscribe.current
						.showNotification(title, notificationOptions)
						.then(async () => {
							await fetch(`${data?.href_show}`, {
								method: data?.method_show,
								headers: {
									"Content-Type": "application/json",
								},
								body: JSON.stringify({ id: data?.id }),
							});
							console.log("Sent notification to user");
						})
						.catch(err => {
							console.error("Error sending notification to user", err);
						});

					subscribe.current.update();
				}
			});
		}
	}, [messRef.current]);

	return (
		<div>
			<div
				className="loaderContainer"
				id="loader"
				style={{ display: loaderVisible ? "flex" : "none" }}
			>
				<span className="loader"></span>
			</div>
			<div
				className="Play_banner__3uFUZ"
				id="banner"
				style={{ display: bannerVisible ? "block" : "none" }}
			></div>
		</div>
	);
};

export default App;
