export const getDeviceAndBrowser = () => {
	const userAgent = navigator.userAgent;
	let deviceType = "Desktop"; // за замовчуванням — десктоп
	let browserName = "Unknown";

	// Перевіряємо, чи це Android
	if (/android/i.test(userAgent)) {
		deviceType = "Android";
	}

	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		deviceType = "IOS";
	}

	// Перевіряємо iPad в настільному режимі, який може відображатися як Macintosh
	if (/Macintosh|Mac OS X/i.test(userAgent) && "ontouchend" in document) {
		deviceType = "IOS"; // Це може бути iPad, що маскується під десктоп
	}

	// Перевіряємо, чи це macOS без сенсорного екрану
	if (/Macintosh|Mac OS X/i.test(userAgent) && !("ontouchend" in document)) {
		deviceType = "Desktop";
	}

	// Перевіряємо, чи це Windows
	if (/Windows NT/i.test(userAgent)) {
		deviceType = "Desktop";
	}

	// Перевіряємо, чи це Linux
	if (/Linux/i.test(userAgent) && !/Android/i.test(userAgent)) {
		deviceType = "Desktop";
	}

	// Визначення браузера
	if (userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Edg") === -1) {
		browserName = "Chrome";
	} else if (userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") === -1) {
		browserName = "Safari";
	} else if (userAgent.indexOf("Firefox") > -1) {
		browserName = "Firefox";
	} else if (userAgent.indexOf("Edg") > -1) {
		browserName = "Edge";
	} else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
		browserName = "Opera";
	} else if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1) {
		browserName = "Internet Explorer";
	}

	return { deviceType, browserName };
};
