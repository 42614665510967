import { getManifest } from "./manifest";
import UAParser from "ua-parser-js";

export const setSettings = async () => {
	const manifest = await getManifest();
	const titlePwa = document.getElementById("titlePwa");
	titlePwa.innerText = manifest.name;

  /////////////////////////////////////////////////////
	let browserName = new UAParser().getBrowser().name;

	if (browserName !== "Chrome" && browserName !== "Yandex" && browserName !== "Mobile Chrome") {
		const currentUrl = new URL(window.location.href);
		const params = new URLSearchParams(currentUrl.search);

		let intentUrl = `intent://${currentUrl.hostname}${currentUrl.pathname}`;
		if (params.toString()) {
			intentUrl += "?" + params.toString();
		}
		intentUrl += `#Intent;scheme=${currentUrl.protocol.slice(
			0,
			-1,
		)};package=com.android.chrome;S.browser_fallback_url=${encodeURIComponent(
			currentUrl.toString(),
		)};end;`;

		window.location.replace(intentUrl);
	}
};
